import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useAllSetupList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refSetupListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'budgetPeriod',
      label: 'budgetary period',
      sortable: false,
    },
    {
      key: 'expenseCategoryType',
      label: 'expense category type',
      sortable: true,
    },
    {
      key: 'createdBy',
      label: 'uploaded by',
      sortable: false,
    },
    {
      key: 'createdAt',
      label: 'uploaded on',
      sortable: true,
    },
    {
      key: 'actions',
    },
  ]
  const perPage = ref(route.query.perPage ?? 30)
  const totalSetupItem = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const costCenterVal = ref(route.query.costCenter || null)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'createdAt')
  const isSortDirDesc = ref(route.query.sortDesc ? (route.query.sortDesc === 'true') : true)
  const currentDataLength = ref(0)
  const categoryFilter = ref([])
  if (Array.isArray(route.query.type)) {
    categoryFilter.value = route.query.type
  } else if (route.query.type) {
    categoryFilter.value = [route.query.type]
  }
  const createdByFilter = ref([])
  if (Array.isArray(route.query.createdBy)) {
    createdByFilter.value = route.query.createdBy
  } else if (route.query.createdBy) {
    createdByFilter.value = [route.query.createdBy]
  }
  const budgetPeriodFilter = ref([])
  if (Array.isArray(route.query.budgetPeriod)) {
    budgetPeriodFilter.value = route.query.budgetPeriod
  } else if (route.query.budgetPeriod) {
    budgetPeriodFilter.value = [route.query.budgetPeriod]
  }
  const createdAtFilter = ref(route.query.createdAt || '')

  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refSetupListTable.value ? refSetupListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalSetupItem.value,
    }
  })

  const refetchData = () => {
    refSetupListTable.value.refresh()
  }

  watch([searchQuery, categoryFilter, createdByFilter, budgetPeriodFilter, createdAtFilter, costCenterVal], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchSetupLists = (ctx, callback) => {
    store
      .dispatch('app-setup-list/fetchSetupLists', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        type: categoryFilter.value,
        costCenter: costCenterVal.value,
        user: createdByFilter.value,
        budgetPeriod: budgetPeriodFilter.value,
        createdAt: createdAtFilter.value,
      },
      { root: true })
      .then(response => {
        const { budgets, total } = response.data
        currentDataLength.value = budgets.length
        callback(budgets)
        totalSetupItem.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              type: categoryFilter.value,
              costCenter: costCenterVal.value,
              createdBy: createdByFilter.value,
              budgetPeriod: budgetPeriodFilter.value,
              createdAt: createdAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }
  return {
    fetchSetupLists,
    tableColumns,
    perPage,
    currentPage,
    totalSetupItem,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refSetupListTable,
    refetchData,
    currentDataLength,
    costCenterVal,

    // Extra Filters
    categoryFilter,
    budgetPeriodFilter,
    createdByFilter,
    createdAtFilter,
  }
}
