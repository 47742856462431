<template>

  <div class="service__request sr__listPage">
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="7"
        md="9"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h2 class="custom-header-title float-left pr-1 mb-0">
              Budget Setup
            </h2>
          </b-col>
        </b-row>
      </b-col>

      <!-- Content Right -->
      <b-col
        class="content-header-right text-right mb-1"
        md="3"
        cols="5"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="success"
          class="mobile_create_button"
          :to="{name: 'budgeting-budget-setup-create'}"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
            size="16"
          />
          <span class="align-middle d-md-block d-none">Create New Budget</span>
          <span class="align-middle d-md-none d-block">New</span>
        </b-button>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left booking_content_wrapper mb-2"
        cols="12"
        md="12"
      >
        <!-- stock-list table  -->
        <div class="mt-2">
          <!-- Table Container Card -->
          <b-card
            no-body
            class="mb-0"
          >
            <div class="m-2">

              <!-- Table Top -->
              <b-row>
                <b-col
                  md="2"
                />
                <b-col
                  v-if="isMobile()"
                  cols="10"
                  md="5"
                  class="d-flex align-items-center justify-content-space_between mb-1 mb-md-0"
                >
                  <span class="text-muted">Showing <br>{{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                </b-col>
                <!-- Per Page -->
                <b-col
                  v-else
                  cols="10"
                  md="5"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                >
                  <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
                </b-col>
                <!-- Search -->
                <b-col
                  v-if="$route.name == 'budgeting-budget-setup'"
                  cols="10"
                  md="5"
                >
                  <div class="d-flex align-items-center justify-content-end">
                    <a
                      href="javascript:void(0)"
                      class="nav-link nav-link-search"
                      @click="showSearchBar = !showSearchBar"
                    >
                      <feather-icon
                        icon="SearchIcon"
                        size="21"
                        class="mr-50"
                      />
                      <span class="align-middle">Search</span>
                    </a>
                    <b-input-group
                      v-if="showSearchBar"
                      class="input-group-merge mr-50"
                    >
                      <b-form-input
                        v-model="searchQuery"
                        placeholder="Search..."
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          icon="XIcon"
                          class="cursor-pointer"
                          @click="searchQuery = ''; showSearchBar = !showSearchBar"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <b-dropdown
                      id="dropdown-Team-form"
                      ref="filter_dropdown_setup_list"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      class="dropdown-modal"
                      right
                      variant="link"
                    >
                      <template #button-content>
                        <feather-icon
                          icon="FilterIcon"
                          size="21"
                          class="align-middle mr-50"
                        />
                        <span class="mr-1">Filters</span>
                      </template>
                      <setup-list-filter
                        :created-at.sync="createdAtFilter"
                        :category.sync="categoryFilter"
                        :category-options="categoryOptions"
                        :budget-period-filter.sync="budgetPeriodFilter"
                        :budget-period-options="budgetPeriodOptions"
                        :created-by-filter.sync="createdByFilter"
                        :user-options="userOptions"
                        @emitHideFilter="hideFilter"
                        @emitShowFilter="showFilter"
                      />
                    </b-dropdown>
                  </div>
                </b-col>
              </b-row>
              <div v-if="categoryFilter.length || budgetPeriodFilter.length || createdByFilter.length || createdAtFilter">
                <hr class="dividerHR filter-divider">
                <b-row>

                  <!-- Per Page -->
                  <b-col
                    cols="12"
                    md="12"
                    class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                  >
                    <ul
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="(category, index) in categoryFilter"
                        :key="index"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromCategoryFilter(index)"
                        >
                          <span class="text-capitalize">{{ category }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        v-for="period in budgetPeriodFilter"
                        :key="period"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromBudgetPeriodFilter(period)"
                        >
                          <span class="text-capitalize">{{ resolveBudgetPeriodName(period) }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        v-for="usr in createdByFilter"
                        :key="usr"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="removeFromUserFilter(usr)"
                        >
                          <span class="text-capitalize">{{ resolveUserName(usr) }}</span>
                        </b-form-tag>
                      </li>
                      <li
                        v-if="createdAtFilter"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          class="mb-50"
                          variant="danger"
                          @remove="createdAtFilter = ''"
                        >
                          {{ createdAtFilter }}
                        </b-form-tag>
                      </li>
                      <li
                        class="list-inline-item"
                      >
                        <span
                          class="text-danger clear-filter-text mb-50"
                          @click="clearAllFilters"
                        >
                          Clear Filters
                        </span>
                      </li>
                    </ul>
                  </b-col>
                </b-row>
              </div>
            </div>

            <b-row class="no-gutters v-sticky-sidebar-container">
              <b-col
                sm="12"
                md="2"
                lg="2"
                style="box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);"
              >
                <vue-sticky-sidebar
                  class="sidebar"
                  :top-spacing="topSpacing"
                  container-selector=".v-sticky-sidebar-container"
                  inner-wrapper-selector=".sidebar__inner"
                >
                  <div
                    v-if="isMobile()"
                    class="p-2"
                  >
                    <b-form-group
                      label="Cost Centre"
                      label-for="cost-center-dropdown"
                    >
                      <v-select
                        id="cost-center-dropdown"
                        v-model="costCenterVal"
                        label="name"
                        :options="allActiveCostCenter"
                        :reduce="costCenter => costCenter._id"
                        :clearable="false"
                      />
                    </b-form-group>
                  </div>
                  <div
                    v-else
                    class="cost-center-wrapper"
                  >
                    <b-form-radio-group
                      id="selectedCostCenter"
                      v-model="costCenterVal"
                      button-variant="flat-primary"
                      buttons
                      stacked
                    >
                      <b-form-radio
                        v-for="(costCenterValue, costCenterIndex) in allActiveCostCenter"
                        :id="'cost-center-radio'+ costCenterIndex"
                        :key="costCenterIndex"
                        class="rounded-0"
                        :value="costCenterValue._id"
                      >
                        <div class="single-cost-center">
                          <div class="content-wrapper">
                            <h4 class="cost-center-name">
                              {{ costCenterValue.name }}
                            </h4>
                          </div>
                        </div>
                      </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </vue-sticky-sidebar>
              </b-col>
              <b-col
                sm="12"
                md="10"
                lg="10"
              >
                <b-table
                  ref="refSetupListTable"
                  :items="fetchSetupLists"
                  responsive
                  class="min-height-table"
                  :fields="tableColumns"
                  primary-key="_id"
                  :sort-by.sync="sortBy"
                  show-empty
                  no-sort-reset
                  empty-text="No matching records found"
                  :sort-desc.sync="isSortDirDesc"
                >
                  <!-- Column: Item Details -->
                  <template #cell(budgetPeriod)="data">
                    <span class="">{{ data.item.budgetPeriod.name || '-' }}</span>
                    <br>
                    <span class="text-bold-black">{{ dateFormat(data.item.budgetPeriod.start) + ' - ' + dateFormat(data.item.budgetPeriod.end) }}</span>
                  </template>
                  <!-- Column: Category -->
                  <template #cell(createdBy)="data">
                    <span class="">{{ data.item.createdBy.name || '-' }}</span>
                  </template>
                  <!-- Column: brand -->
                  <template #cell(createdAt)="data">
                    <span class="">{{ dateFormatWithTime(data.item.createdAt) }}</span>
                  </template>
                  <!-- Column: Actions -->
                  <template
                    v-if="canViewThisAction('update', 'BudgetSetup') || canViewThisAction('show', 'BudgetSetup') "
                    #cell(actions)="data"
                  >
                    <div style="white-space: nowrap;">
                      <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL"
                      >

                        <template #button-content>
                          <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                          />
                        </template>

                        <b-dropdown-item
                          v-if="canViewThisAction('show', 'BudgetSetup')"
                          :to="{ name: 'budgeting-budget-setup-view', params: { id: data.item._id } }"
                        >
                          <feather-icon icon="EditIcon" />
                          <span class="align-middle ml-50">View</span>
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="canViewThisAction('updated', 'BudgetSetup')"
                          :to="{ name: 'budgeting-budget-setup-edit', params: { id: data.item._id } }"
                        >
                          <feather-icon icon="EyeIcon" />
                          <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </template>

                </b-table>
              </b-col>
            </b-row>
          </b-card>

          <div
            v-if="totalSetupItem > perPage"
            class="mx-2 mb-2"
          >
            <b-row>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="12"
                class="d-flex align-items-center justify-content-center pagination-content"
              >
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalSetupItem"
                  :per-page="perPage"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  align="center"
                  prev-text="Prev"
                  next-text="Next"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                    <span>Prev</span>
                  </template>
                  <template #next-text>
                    <span>Next</span>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <!-- ./ stock-list table  -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BButton, BCol, BFormRadioGroup, BFormRadio, BCard, BFormGroup, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BDropdown, BDropdownItem, BPagination, BFormTag,
} from 'bootstrap-vue'
import vueStickySidebar from 'vue-sticky-sidebar'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { ref, onUnmounted } from '@vue/composition-api'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

// eslint-disable-next-line import/no-cycle
import useAllSetupList from './useSetupLists'
import SetupListStoreModule from './setupListsStoreModule'
import SetupListFilter from './SetupListFilter.vue'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BFormRadioGroup,
    BFormRadio,
    BCard,
    BFormInput,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,
    BFormGroup,

    vSelect,
    'vue-sticky-sidebar': vueStickySidebar,
    SetupListFilter,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      user: store.state.auth.userData,
      topSpacing: 135,
      // eslint-disable-next-line global-require
      defaultImage: require('@/assets/images/placeholder/placeholder_image.png'),
      allActiveCostCenter: [],
      selectedCostCenter: '',
      budgetPeriodOptions: [],
      userOptions: [],
      categoryOptions: [
        'Operating Expenses',
        'CAPEX (Fixed Assets)',
        'CAPEX (Lease Assets)',
        'Projects',
      ],
      items: [],
      vStoreSelect: '',
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },
  created() {
    this.$http.get('budget/cost-center/respond-with/name-and-id')
      .then(response => {
        this.allActiveCostCenter = response.data.costCenters
        if (this.allActiveCostCenter.length) {
          this.costCenterVal = this.$route.query.costCenter || this.allActiveCostCenter[0]._id
        }
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })

    this.$http.get('budget/budget/extra-options/user-budget-period-all')
      .then(response => {
        this.budgetPeriodOptions = response.data.budgetPeriods
        this.userOptions = response.data.users
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_setup_list.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_setup_list.show(true)
    },
    removeFromCategoryFilter(index) {
      if (index > -1) {
        this.categoryFilter.splice(index, 1)
      }
    },
    removeFromBudgetPeriodFilter(item) {
      const index = this.budgetPeriodFilter.indexOf(item)
      if (index > -1) {
        this.budgetPeriodFilter.splice(index, 1)
      }
    },
    removeFromUserFilter(item) {
      const index = this.createdByFilter.indexOf(item)
      if (index > -1) {
        this.createdByFilter.splice(index, 1)
      }
    },
    resolveUserName(id) {
      const user = this.userOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },
    resolveBudgetPeriodName(id) {
      const period = this.budgetPeriodOptions.find(o => o._id === id)
      if (period) {
        return `${period.name} (${this.dateFormat(period.start)} - ${this.dateFormat(period.end)})` || ''
      }
      return ''
    },
    clearAllFilters() {
      this.categoryFilter = []
      this.budgetPeriodFilter = []
      this.createdByFilter = []
      this.createdAtFilter = ''
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const STOCK_LIST_APP_STORE_MODULE_NAME = 'app-setup-list'

    // Register module
    if (!store.hasModule(STOCK_LIST_APP_STORE_MODULE_NAME)) store.registerModule(STOCK_LIST_APP_STORE_MODULE_NAME, SetupListStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STOCK_LIST_APP_STORE_MODULE_NAME)) store.unregisterModule(STOCK_LIST_APP_STORE_MODULE_NAME)
    })

    const { canViewThisAction } = useAclUtils()

    const {
      fetchSetupLists,
      tableColumns,
      perPage,
      currentPage,
      totalSetupItem,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSetupListTable,
      refetchData,
      currentDataLength,
      costCenterVal,

      // Extra Filter
      categoryFilter,
      createdAtFilter,
      budgetPeriodFilter,
      createdByFilter,
    } = useAllSetupList()

    return {

      // Sidebar
      fetchSetupLists,
      tableColumns,
      perPage,
      currentPage,
      totalSetupItem,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refSetupListTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      currentDataLength,

      // Filter
      canViewThisAction,
      costCenterVal,

      // Extra Filters
      categoryFilter,
      createdAtFilter,
      budgetPeriodFilter,
      createdByFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.filter-dropdown{
  width: 100%;
  margin-top: 16px;
}
.per-page-selector {
  width: 90px;
}
.modal .form-control {
    margin: 0;
}
.img-preview-block{
      box-sizing: border-box;
      width: 38px;
      height: 38px;
      border: 1px solid #D2DCEA;
      border-radius: 5px;
  }

@media (max-width: 767px) {
.StockLists_fields {
    padding: 10px 0 0 0;
  }

.new_design_requests {
    margin-top: 10px !important;
}

.modal .form-control {
    margin-top: 5px;
}

.filter-dropdown{
  width: 100%;
}
}

// start store section css
.cost-center-wrapper {
   display: flex;
   flex-direction: column;
   border-top: 1px solid #e7edf5;
   max-height: calc(100vh - 125px);
   overflow-y: auto;
}
.cost-center-store{
    width: 100%;
    padding: 8px;
}
.content-wrapper{
    justify-content: center;
    align-items: flex-start;
    gap: 3px;
    margin: 0;
    text-align: left;
}
.content-wrapper .cost-center-name{
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #000000;
}
// ./end store section css
</style>
