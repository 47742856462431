import { render, staticRenderFns } from "./SetupList.vue?vue&type=template&id=49da5f90&scoped=true&"
import script from "./SetupList.vue?vue&type=script&lang=js&"
export * from "./SetupList.vue?vue&type=script&lang=js&"
import style0 from "./SetupList.vue?vue&type=style&index=0&id=49da5f90&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49da5f90",
  null
  
)

export default component.exports