<template>
  <b-dropdown-form
    class="py-1 dropdown-filter-form-n"
  >
    <b-row>
      <b-col cols="10">
        <p>Filters</p>
      </b-col>
      <b-col cols="2">
        <feather-icon
          icon="XIcon"
          size="16"
          class="align-right"
          @click="$emit('emitHideFilter')"
        />
      </b-col>
    </b-row>
    <b-form-group
      label="Budgetary Period"
      @click.native.stop
    >
      <v-select
        v-model="budgetPeriodValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="budgetPeriodOptions"
        :reduce="index => index._id"
      >
        <template #option="{ name, start, end }">
          <span style="font-size: 14px;font-weight: 400;"> {{ name }}</span>
          <br>
          <span
            style="font-weight: 500;font-size: 12px;"
            class="text-muted"
          >
            {{ dateFormat(start) }} - {{ dateFormat(end) }}
          </span>
        </template>
      </v-select>
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Expense Category Type"
      @click.native.stop
    >
      <v-select
        v-model="categoryValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="categoryOptions"
        :reduce="index => index"
      />
    </b-form-group>
    <hr class="dividerHR filter-divider">
    <b-form-group
      label="Uploaded By"
      @click.native.stop
    >
      <v-select
        v-model="createdByValue"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Select from list"
        multiple
        :options="userOptions"
        :reduce="index => index._id"
      />
    </b-form-group>
    <b-button
      variant="outline-primary"
      size="sm"
      class="mr-1"
      @click="clearFilter"
    >
      Clear All
    </b-button>
    <b-button
      variant="primary"
      size="sm"
      @click="applyFilter"
    >
      Apply Filter
    </b-button>
  </b-dropdown-form>
</template>

<script>
import {
  BDropdownForm, BFormGroup, BButton, BRow, BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import '@core/scss/vue/libs/vue-flatpicker.scss'

export default {
  components: {
    BButton,
    BDropdownForm,
    BFormGroup,
    BRow,
    BCol,
    vSelect,
  },
  props: {
    category: {
      type: [Array, null],
      default: null,
    },
    categoryOptions: {
      type: Array,
      required: true,
    },
    createdByFilter: {
      type: [Array, null],
      default: null,
    },
    userOptions: {
      type: Array,
      required: true,
    },
    budgetPeriodFilter: {
      type: [Array, null],
      default: null,
    },
    budgetPeriodOptions: {
      type: Array,
      required: true,
    },
    createdAtFilter: {
      type: [String, null],
      default: null,
    },
  },
  data() {
    return {
      createdAtFilterValue: this.createdAtFilter,
      categoryValue: JSON.parse(JSON.stringify(this.category)) || [],
      createdByValue: JSON.parse(JSON.stringify(this.createdByFilter)) || [],
      budgetPeriodValue: JSON.parse(JSON.stringify(this.budgetPeriodFilter)) || [],
      flatPickrConfig: {
        wrap: true, mode: 'range', dateFormat: 'd/m/Y', enableTime: false, static: true, disableMobile: true,
      },
    }
  },
  watch: {
    category: {
      immediate: true,
      handler(val) {
        this.categoryValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    createdByFilter: {
      immediate: true,
      handler(val) {
        this.createdByValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    budgetPeriodFilter: {
      immediate: true,
      handler(val) {
        this.budgetPeriodValue = JSON.parse(JSON.stringify(val)) || []
      },
    },
    // createdAtFilter: {
    //   immediate: true,
    //   handler(val) {
    //     this.createdAtFilterValue = val
    //   },
    // },
  },
  methods: {
    showAgainDropdown() {
      if (this.createdAtFilterValue) {
        this.$emit('emitShowFilter')
      }
    },
    applyFilter() {
      this.$emit('update:category', this.categoryValue)
      this.$emit('update:createdByFilter', this.createdByValue)
      this.$emit('update:budgetPeriodFilter', this.budgetPeriodValue)
      // this.$emit('update:createdAt', this.createdAtFilterValue)

      this.$emit('emitHideFilter')
    },
    clearFilter() {
      // this.createdAtFilterValue = ''
      this.categoryFilterValue = []
      this.createdByValue = []
      this.budgetPeriodValue = []

      this.$emit('update:category', this.categoryValue)
      this.$emit('update:createdByFilter', this.createdByValue)
      this.$emit('update:budgetPeriodFilter', this.budgetPeriodValue)
      // this.$emit('update:createdAt', this.createdAtFilterValue)

      this.$emit('emitHideFilter')
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
